<template>
  <div class="search-sidebar--dropdown">
    <div
      v-b-toggle.collapseDistrict
      class="d-flex"
      style="justify-content: space-between"
      @click="open"
    >
      <h3 class="filter--title">{{ $t('directory.search.subtitle') }}</h3>
      <i :class="!isOpened ? 'ion-chevron-down' : 'ion-chevron-up'"></i>
    </div>
    <b-collapse visible id="collapseDistrict">
      <div class="dropdown-item" v-for="(city, key) in cities" :key="key">
        <div class="custom-control custom-radio">
          <input
            type="radio"
            :id="'city' + key"
            v-model="selectedCity"
            :value="city.id"
            class="custom-control-input"
            :disabled="isLoading"
          />
          <label class="custom-control-label" :for="'city' + key">
            <span>{{ capitalize(city.name) }}</span>
          </label>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

export default {
  mixins: [HelperMixin],
  data: () => ({
    isOpened: true,
  }),
  computed: {
    ...mapState({
      cities: (state) => state.v2.directory.cities,
      isLoading: (state) => state.v2.search.isLoading,
    }),
    selectedCity: {
      get() {
        let selectedCity = this.$store.state.v2.directory.selectedCity;
        return selectedCity ? selectedCity.id : null;
      },
      set(val) {
        this.$store.dispatch('v2/directory/setSelectedCityById', val);
      },
    },
  },
  methods: {
    open() {
      this.isOpened = !this.isOpened;
    },
    onUpdateFilter() {},
  },
};
</script>

<style lang="scss" scoped>
.custom-control-label {
  span {
    white-space: normal;
  }
}
</style>
